<template>
  <vs-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="!errors.first('basic.name') && formData.name != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.name') ? true : false"
          v-validate="'required'"
          name="name"
          data-vv-as="name"
          label="Name"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.name')"
          v-model="formData.name"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="!errors.first('basic.unit') && formData.unit != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.unit') ? true : false"
          v-validate="'required'"
          name="unit"
          data-vv-as="unit"
          label="Unit"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.unit')"
          v-model="formData.unit"
        />
      </div>
    </div>
    <!--    <div class="vx-row">-->
    <!--      <div class="vx-col w-full">-->
    <!--        <div class="mt-8">-->
    <!--          &lt;!&ndash;          <vs-button class="ml-auto mt-2" type="border" @click="addBodyParts">+ Add Body Parts</vs-button>&ndash;&gt;-->
    <!--          <vs-button class="ml-auto mt-2" type="border" @click="openPopup">+ Add Body Parts</vs-button>-->
    <!--          &lt;!&ndash; <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Reset</vs-button> &ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <vs-row class="mt-5">-->
    <!--      <div class="vx-col w-full">-->
    <!--        <vs-col class="mb-3"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="3"-->
    <!--                 v-for="(applicableArea,index) in formData.applicableAreas"-->
    <!--                :key="index">-->
    <!--          <vs-button color="success" type="border" icon="cancel" @click="openRemovePopup(index)">{{ applicableArea }}-->
    <!--          </vs-button>-->

    <!--        </vs-col>-->
    <!--      </div>-->
    <!--    </vs-row>-->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-3 vx-col w-full">
          <label class="label" name="treatment-type">Body Parts</label>
          <v-select
            multiple
            :closeOnSelect="false"
            label="name"
            v-validate="'required'"
            danger-text="This field is invalid"
            :options="optionBodyParts"
            v-model="formData.applicableAreas"
            data-vv-as="applicableAreas"
            data-vv-scope="basic"
            name="applicableAreas"
            class="w-full"
          >
          </v-select>
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.applicableAreas") }}
            </span>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-3 vx-col w-full">
          <label class="label" name="treatment-type">Treatment Type</label>

          <vs-select
            placeholder="Select treatment type"
            autocomplete
            v-model="selectedTreatmentType"
            v-validate="'required'"
            data-vv-as="treatmentType"
            data-vv-scope="basic"
            name="treatmentType"
            class="w-full"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in treatmentTypesOption"
              :clearable="false"
            />
          </vs-select>
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.treatmentType") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <label class="label" name="treatment-type">Brand</label>

          <vs-select
            placeholder="Select brand"
            autocomplete
            v-model="formData.brandId"
            name="brand"
            class="w-full"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.brandName"
              v-for="(item, index) in brands"
              :clearable="false"
            />
          </vs-select>
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.brand") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <!-- all defaultLowBalanceAmount should be a number -->
        <vs-input
          :success="!errors.first('basic.defaultLowBalanceAmount') && formData.unit != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.defaultLowBalanceAmount') ? true : false"
          v-validate="'numeric'"
          name="defaultLowBalanceAmount"
          data-vv-as="defaultLowBalanceAmount"
          label="Default Low Balance Amount"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.defaultLowBalanceAmount')"
          v-model="formData.defaultLowBalanceAmount"
        />
      </div>
      <div v-if="isIvTheraphy" class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <label class="label" name="treatment-type">Ingredients</label>
          <vs-textarea
            :success="!errors.first('basic.ingredients') && formData.ingredients != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.ingredients') ? true : false"
            v-validate="'required'"
            data-vv-as="ingredients"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.ingredients')"
            height="200px"
            name="ingredients"
            v-model="formData.ingredients"
            />
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.ingredients") }}
            </span>
          </div>
        </div>
      </div>

      <div
        class="vx-col md:w-1/2 w-full"
        v-if="
          this.productDetail.treatmentType &&
          this.productDetail.treatmentType.treatmentTiming
        "
      >
        <div class="mt-3 vx-col w-full">
          <label class="label">Timing of Treatment</label>
          <div
            class="p-2 border my-4"
            style="border: solid 1px #aaa; border-radius: 5px"
          >
            {{ this.productDetail.treatmentType.treatmentTiming }}
          </div>
          <vs-checkbox v-model="formData.timingOverride"
            >Override Timing of Treatment</vs-checkbox
          >
          <vs-textarea
            :success="
              !errors.first('basic.treatmentTiming') &&
              formData.treatmentTiming != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.treatmentTiming') ? true : false"
            v-validate="formData.timingOverride && 'required'"
            name="treatmentTiming"
            data-vv-as="Treatment Timing"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.treatmentTiming')"
            v-model="formData.treatmentTiming"
            style="margin: 5px 0 !important"
          />
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.treatmentTiming") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <label class="label" name="treatment-type">Aftercare Template</label>

          <vs-select
            placeholder="Select aftercare template"
            autocomplete
            v-model="formData.aftercaretemplate"
            name="aftercaretemplate"
            class="w-full"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in aftercareTemplatesOption"
              :clearable="false"
            />
          </vs-select>
          <div
            class="
              con-text-validation
              span-text-validation-danger
              vs-input--text-validation-span
              v-enter-to
            "
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.aftercaretemplate") }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <label class="custom-label">Description</label>
        <vs-textarea
          height="150px"
          v-model="formData.description"/>
      </div>
    </div>

    <vs-row>
      <vs-col vs-type="flex" vs-align="left" vs-w="12">
        <div class="w-full m-5" align="right">
          <vs-button @click="saveData" icon-pack="feather" class="mr-2"
            ><span v-if="update">Update Product</span
            ><span v-else>Add Product</span></vs-button
          >
          <vs-button
            color="primary"
            @click="addOnlyBodyPartsPopupShow"
            icon-pack="feather"
            >Create Applicable Area</vs-button
          >
        </div>
      </vs-col>
    </vs-row>

    <vs-popup
      classContent="popup-example"
      title="Add Applicable Area"
      :active.sync="popupActivo2"
    >
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">Body Parts</label>

        <v-select
          v-model="newBodyParts.name"
          :clearable="false"
          label="name"
          :reduce="(label) => label.name"
          :options="bodyParts"
          v-validate="'required'"
          name="applicableAreas"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          :success="
            !errors.first('body-parts-add.applicableAreas') &&
            newBodyParts.name != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          :danger="
            errors.first('body-parts-add.applicableAreas') ? true : false
          "
          data-vv-as="applicableAreas"
          class="w-full"
          data-vv-scope="body-parts-add"
          :danger-text="errors.first('body-parts-add.applicableAreas')"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('applicableAreas')"
          >{{ errors.first("applicableAreas") }}</span
        >
      </div>

      <vs-button color="success" class="ml-auto mt-4" @click="addBodyParts">
        Add Applicable Area in Product</vs-button
      >
      <vs-button
        color="primary"
        class="ml-2 mt-4"
        @click="addOnlyBodyPartsPopupShow"
      >
        Create Applicable Area</vs-button
      >
    </vs-popup>
    <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
      <p align="center">Are you sure you want to delete this notification ?</p>
      <br />
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        vs-mt="6"
      >
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5" align="right">
            <vs-button
              size="lg"
              color="primary"
              @click="removeBodyParts(removeIndex)"
              >Yes, delete</vs-button
            >
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button size="lg" color="danger" @click="popUpDelete = false"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      classContent="popup-example"
      title="Create Applicable Area"
      :active.sync="popupBodyPartsAdd"
    >
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="
            !errors.first('applicableArea.applicableAreaName') &&
            applicableArea.name != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          :danger="
            errors.first('applicableArea.applicableAreaName') ? true : false
          "
          v-validate="'required'"
          name="applicableAreaName"
          data-vv-as="applicableAreaName"
          label="Name"
          class="w-full"
          data-vv-scope="applicableArea"
          :danger-text="errors.first('applicableArea.applicableAreaName')"
          v-model="applicableArea.name"
        />
      </div>

      <vs-button color="success" class="ml-auto mt-4" @click="addNewBodyParts">
        Add</vs-button
      >
    </vs-popup>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import VueCkeditor from 'vue-ckeditor2';
import { mapActions } from "vuex";

export default {
  name: "ProductForm",
  components: {
    vSelect,
    VueCkeditor
  },
  props: ["productDetail", "update"],
  data() {
    return {
      popupActivo2: false,
      popupBodyPartsAdd: false,
      newBodyParts: {
        name: "",
      },
      applicableArea: {
        name: "",
      },
      removeIndex: 0,
      popUpDelete: false,
      bodyParts: [],
      aftercareTemplatesOption:[],
      treatmentTypesOption: [],
      brands: [],
      formData: {
        brandId: null,
        ...this.productDetail,
      },
      selectedTreatmentType:
        this.productDetail && this.productDetail.treatmentType
          ? this.productDetail.treatmentType._id
          : "",
      isRequired: false,
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        height: 300,
        versionCheck: false,
      },
    };
  },
  created() {
    this.getBodyParts();
    this.fetchTreatmentTypes();
    this.fetchAftercareTemplate();
    this.getBrands();

    if (this.productDetail.timingOverride) {
      this.isRequired = this.productDetail.timingOverride;
    }
  },
  computed: {
    optionBodyParts() {
      return this.bodyParts.map((b) => {
        return b.name;
      });
    },
    isIvTheraphy(){
      const treatment = this.treatmentTypesOption.filter((item)=>(item._id == this.selectedTreatmentType))
      if(treatment.length > 0){
        return treatment[0].name == "IV Therapy" //&& this.$isUsRegion
      }
      return false
    }
  },
  watch: {
    "formData.timingOverride": function (val) {
      this.isRequired = val;
    },
  },
  methods: {
    ...mapActions("product", [
      "fetchAllProductApplicableAreas",
      "fetchProductApplicableAreas",
      "saveNewApplicableArea",
      "fetchBrands",
    ]),
    ...mapActions("treatmentTypes", ["treatmentTypes"]),
    ...mapActions("admin", ["fetchAftercareTemplateList"]),
    fetchAftercareTemplate() {
      this.fetchAftercareTemplateList().then((res) => {
        this.aftercareTemplatesOption = res.data.data;
      });
    },
    fetchTreatmentTypes() {
      this.treatmentTypes({ search: "" }).then((res) => {
        this.treatmentTypesOption = res.data.data;
      });
    },
    getBrands() {
      this.fetchBrands().then(res=>{
        this.brands = [{_id: null, brandName: "No Brand"},...res];
      });
    },
    openPopup() {
      this.popupActivo2 = true;
      this.newBodyParts = {
        name: "",
      };
    },
    async getBodyParts() {
      let self = this;
      this.fetchAllProductApplicableAreas().then((res) => {
        self.bodyParts = res.data.data.docs;
      });
    },
    async addBodyParts() {
      let isValid = await this.$validator.validateAll("body-parts-add");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (this.formData.applicableAreas.includes(this.newBodyParts.name)) {
        this.$vs.notify({
          title: "Error",
          text: "The selected body parts already exists in body parts.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.formData.applicableAreas.push(this.newBodyParts.name);
      this.popupActivo2 = false;
    },
    openRemovePopup(index) {
      this.removeIndex = index;
      this.popUpDelete = true;
    },
    removeBodyParts(index) {
      this.formData.applicableAreas.splice(index, 1);
      this.popUpDelete = false;
    },
    addOnlyBodyPartsPopupShow() {
      this.popupActivo2 = false;
      this.popupBodyPartsAdd = true;
    },
    async addNewBodyParts() {
      let isValid = await this.$validator.validateAll("applicableArea");
      let self = this;
      if (isValid) {
        this.saveNewApplicableArea(this.applicableArea)
          .then((res) => {
            self.getBodyParts();
            self.applicableArea.name = "";
            self.$vs.notify({
              title: "Success",
              text: "Applicable Area added successfully",
              color: "success",
            });
            // self.popupActivo2 = true;
            self.popupBodyPartsAdd = false;

            self.$vs.loading.close();
          })
          .catch((err) => {
            self.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger",
            });
            self.$vs.loading.close();
          });
      }
    },
    async saveData() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }

      this.$emit(
        "saveDetail",
        this.formData,
        this.selectedTreatmentType,
        this.treatmentTypesOption,
      );
    },
  },
};
</script>

<style scoped></style>
